import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import * as nodemailer from 'nodemailer'; 
import axios from 'axios';

export default function JoinBV() {
    const refInput = useRef();

    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[Join us] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);

    },[]);
     const [assoName,setAssoName] = useState('');
     const[assoPHName,setAssoPHName]  = useState('');
     const[assoEmail,setAssoEmail] = useState('');
     const[assoAge,setAssoAge]= useState('');
     

     const handleChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.value;
        const name = event.target.name;
        var re = /^[a-zA-Z\s]*$/;
        if(re.test(event.target.value))
        {
            setAssoName(value);
        }
        else
        {
         alert('Invalid Name.'); 
         return false;
        }   
        
       // alert("value of assoName:" + assoName);
    }
    const handleChangeAge = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.value;
        const name = event.target.name;
        setAssoAge(value);
    }
    const handleChangePhNo = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.value;
        const name = event.target.name;
        setAssoPHName(value);
       // alert("value of assoPH NO :" + assoPHName);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.value;
        const name = event.target.name;
        setAssoEmail(value);
       // alert("value of email :" + assoEmail);
    }

    const submitData = () =>
    {
        if(assoName === undefined || assoName == null)
        {
            alert("Enter name");
            return false;
        }
        if(assoPHName === undefined || assoPHName == null)
        {
            alert("Enter Phone no");
            return false;
        }
        if(assoEmail === undefined || assoEmail == null)
        {
            alert("Enter Email");
            return false;
        }

        if(assoPHName !== undefined)
        {
        if(assoPHName.length !== 10)
        {
            alert("Not a valid Phone no");
            return false;
        }
        }
        if(assoEmail !== undefined && assoEmail !== '')
        {
          if(assoEmail.indexOf('@') < 0)
        {
            alert("Not a valid email");
            return false;
        }
        }
        if(assoAge !== undefined && assoAge <= '18')
        {
           alert("You have to me more than 18 years old to be able to work with us");
           return false;
        }
        if(assoAge.length !== 2 )
        {
            alert("Age more than 99 is not allowed");
            return false;
        }

           let bodyOfEmail = "Name :" + assoName + 
                             "\nAge:" + assoAge +
                             "\nPhone no : " + assoPHName + 
                             " \nEmail id : " + assoEmail;
           let emailSubject = "Associate Joining Request";
           let emailSendTo = "";
           let emailSentFrom = "";
        let payload = {
            Subject: emailSubject,
            Body: bodyOfEmail,
            SendTo: emailSendTo,
            SentFrom: emailSentFrom
        };
     
       // axios.post('http://localhost:8098/sendEmail',payload)
       axios.post('https://www.biggview.com:8098/sendEmail',payload)
        .then(function(response)
        {
         console.log("response is:" + response.data);
         if(response.data == 'Email sent successfully')
         {
         alert("We have received your request. We will soon get in touch with you.");
         }
        })
        .catch(function(error)
        {
           console.log("error is:" + error);
        });
    

    }

    return (
        <div className ="centralized-div">
            <h4><b>Join Us</b></h4>
              {/*<Cover coverClass="noimageHero">
                <Banner 
                title=""
                subtitle = "Join us "
                >
                </Banner>
              </Cover>  */}


<div className ="faqText ">
We are looking for young & dynamic people who want to make their own path in life, by partnering with us as an associate. Partnering with us requires investment & Customer centric approach, with which you can start earning from day one.
<br></br>
Interested candidates can share their details as mentioned below. Our team will get in touch with you and guide you on further proceedings. 

</div>
                <div className="form-group faqText ">  
                    <div className="size-inputs">
                        <div className ="joinUsActualDiv">
                             <div className = "sideTextBoxes">
                             <label htmlFor="size"> &nbsp;  </label>
                               <input type="text" name="name" id="name" value = {assoName} onChange={handleChangeName} className="size-input" placeholder = "Full Name"/>
                             </div>
                             <div className = "sideTextBoxes">
                             <label htmlFor="size"> &nbsp;  </label>
                               <input type="number" name="age" id="age" value = {assoAge} onChange={handleChangeAge} className="size-input" placeholder = "Age"/>
                             </div>
                             <div className = "sideTextBoxes">
                             <label htmlFor="size"> &nbsp;  </label>
                               <input type="number" name="phno" id="phno" value = {assoPHName} onChange={handleChangePhNo} className="size-input" placeholder = "10 digit Cell Number"/>
                             </div>
                             <div className = "sideTextBoxes">
                             <label htmlFor="size"> &nbsp;  </label>
                               <input type="email" name="emailId" id="emailId" value = {assoEmail} onChange={handleChangeEmail} className="size-input" placeholder = "Email ID"/>
                             </div>
                             <div className = "sideTextBoxes">
                             <label htmlFor="size"> &nbsp;  </label>
                               <button className = "blueButton borderedLinks" onClick={submitData}>Submit</button> 
                             </div>
                            <br></br>   <br></br> <br></br>
                        </div>            
                    </div>
                </div>
                <div className ="whiteBreakThin"> </div>
               <div className ="blueBreakThin"> </div>
               <div className ="whiteBreakThin"> </div>
               <div className ="greenBreakThin"> </div>
               
                
                <div className="form-group">  
                    <div className="size-inputs">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
                
                </div>
                
            
    )
}

