
import React, { useState,useEffect,useRef,Component } from 'react';
import { FaCocktail, FaHiking, FaShuttleVan, FaBeer,FaAddressBook,FaInfo,FaHome,FaDollarSign,FaTv,FaGamepad,FaPhabricator } from 'react-icons/fa';
import Title from './Title';
import ReactPlayer from "react-player";


interface Service {
    icon: JSX.Element | string;
    title: string;
    info: string;
}

interface IStateServices {
    services: Service[];
}

export default class Services extends Component<{}, IStateServices> {
    

    public readonly state: Readonly<IStateServices> = {
        services: [
            {
                icon: <FaHome />,
                title: "Why Bigg View?",
                info: "Convenience & Safety in the Comfort of Home."
            },
            {
                icon: <FaTv/>,
                title: "View Big",
                info: "Upto 120” screen viewing size"
            },
            {
                icon: <FaPhabricator />,
                title: "View",
                info: "Explore Informal viewing"
            },
            {
                icon: <FaGamepad />,
                title: "Gaming",
                info: "Amazing  Gaming Experience (PS5)"
            },
        ],
        
    }


    public render() {
        const { services } = this.state;
        
        return (
            <div>
            <section className="services">
                {/*<Title title="Why Book our Services ?" />
                <div className="services-center">
                <img src={require('../assets/images/iconImage1.png')} className="service"  alt=" Bigg View" />
                <img src={require('../assets/images/iconImage3.png')} className="service" alt="Why Bigg View" />
                <img src={require('../assets/images/iconImage4.png')} className="service" alt="Why Bigg View" />
                </div>
        <br></br><br></br><br></br><br></br> */}
               <Title title="New OTT Releases – Kannada / Telegu / Tamil / Malayalam #" />

              <div className="services-center-secondLine">
              <img src={require('../assets/images/Regional/Guntur_Kaaram.png')} className="service"   alt="Guntur_Kaaram Telegu movie on biggview at home" />
              <img src={require('../assets/images/Regional/Kannur_Squad.png')} className="service"   alt="Kannur_Squad Malayalam movie on biggview at home" />
              <img src={require('../assets/images/Regional/Kushi.png')} className="service"   alt="Kushi Telegu movie on biggview at home7" />
              <img src={require('../assets/images/Regional/RDX.png')} className="service"   alt="RDX Malayalam movie on biggview at home" />
              <img src={require('../assets/images/Regional/Adiyae.png')} className="service"   alt="Adiyae Tamil Movie on biggview at home" />            
              
              </div>
              <br></br><br></br><br></br>

              
              

               <Title title="New OTT Releases - Hindi #:" />
            <div className="services-center-secondLine">
              <img src={require('../assets/images/New_Images/Bhakshak.png')} className="service"   alt="Bhakshak movie on biggview at home" />
              <img src={require('../assets/images/New_Images/Sam_Bahadur.png')} className="service"   alt="Sam_Bahadur movie on biggview at home" />
              <img src={require('../assets/images/New_Images/Animal.png')} className="service"   alt="Animal movie on biggview at home" />
              <img src={require('../assets/images/New_Images/Pippa.png')} className="service"   alt="Pippa movie on biggview at home" />
              <img src={require('../assets/images/New_Images/Fukrey.png')} className="service"   alt="Fukrey movie on biggview at home" />
              <img src={require('../assets/images/New_Images/Jawan.png')} className="service"   alt="Jawan movie on biggview at home" />
              <img src={require('../assets/images/New_Images/Joram.png')} className="service"   alt="Joram movie on biggview at home" />
              <img src={require('../assets/images/New_Images/Apurva.png')} className="service"   alt="Apurva movie on biggview at home" />

            </div>
            <br></br><br></br><br></br>

            <Title title="New OTT Releases - English #:" />
            <div className="services-center-secondLine">
             <img src={require('../assets/images/New_Images/Society_Of_The_Snow.png')} className="service"   alt="Society_Of_The_Snow movie on biggview at home" />
             <img src={require('../assets/images/New_Images/Wonka.png')} className="service"   alt="Wonka movie on biggview at home" />
             <img src={require('../assets/images/New_Images/Aquaman_And_The_Lost_Kingdom.png')} className="service"   alt="Aquaman_And_The_Lost_Kingdom movie on biggview at home" />
             <img src={require('../assets/images/New_Images/The_Nun_2.png')} className="service"   alt="The_Nun_2 movie on biggview at home" />
             <img src={require('../assets/images/New_Images/The_Equalizer_3.png')} className="service"   alt="The_Equalizer_3 movie on biggview at home" />
             <img src={require('../assets/images/New_Images/Meg2_The_Trench.png')} className="service"   alt="Meg2_The_Trench movie on biggview at home" />
             <img src={require('../assets/images/New_Images/Mission_Impossible_Dead_Reckoning.png')} className="service"   alt="Mission_Impossible_Dead_Reckoning movie on biggview at home" />
             <img src={require('../assets/images/New_Images/Transformers_Rise_Of_The_Beast.png')} className="service"   alt="Transformers_Rise_Of_The_Beast movie on biggview at home" />

            </div> 
            <br></br><br></br><br></br>
            {/* <Title title="3D Movie Collection" /> */}
               {/* <div className="services-center-secondLine"> */}
               {/* <img src={require('../assets/images/3DMovies/Jack_The_Giant_Slayer_3D.png')} className="service"   alt="Jack The Giant Slayer 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Spiderman_Home_Comming_3D.png')} className="service"   alt="Spiderman 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Spiderman_Far_from_Home_3D.png')} className="service"   alt="3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Avatar_3D.png')} className="service"   alt="Avatar 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Gravity_3D.png')} className="service"   alt="Gravity 3D movie on biggview at home" />
               
               <img src={require('../assets/images/3DMovies/Kong_skull_island_3D.png')} className="service"   alt="Kong skull Island 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/The_Hobbit_An_Unexpected_Journey_3D.png')} className="service"   alt="The Hobbit An Unexpected Journey 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/The_Hobbit_The_Desolation_of_Smaug_3D.png')} className="service"   alt="The Hobbit The Desolution of Smaug 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/The_Hobbit_The_Battle_of_the_Armies_3D.png')} className="service"   alt="The Hobbit The Battle of the Armies 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Toy_Story_3D.png')} className="service"   alt="Toy Story 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Toy_Story_2_3D.png')} className="service"   alt="Toy Story 2 3D movie on biggview at home" />
               
               <img src={require('../assets/images/3DMovies/Brave_3d.png')} className="service"   alt="Brave 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Kungfu_Panda_3d.png')} className="service"   alt="Kungfu Panda 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Frozen_3D.png')} className="service"   alt="Frozen 3D movie on biggview at home" />
               <img src={require('../assets/images/3DMovies/Much_More_3D.png')} className="service"   alt="More 3D movie on biggview at home" /> */}
               {/* </div>
               <br></br><br></br><br></br> */}

            <Title title="Games" />
            <div className="services-center-secondLine">
            <img src={require('../assets/images/New_Images/Football_game.png')} className="service"   alt="e Football game in Play Station 4 on biggview at Home" /> 
            <img src={require('../assets/images/New_Images/Gran_Turismo_game.png')} className="service"   alt="Gran Turismo game in Play Station 4 on biggview at Home" /> 
            <img src={require('../assets/images/New_Images/Games_God_of_war.png')} className="service"   alt="God of War game in Play Station 4 on biggview at Home" /> 
            <img src={require('../assets/images/New_Images/Game_LastOneOfUs.png')} className="service"   alt="Last One of Us game in Play Station 4 on biggview at Home" /> 
            <img src={require('../assets/images/New_Images/blank.png')} className="service"   alt="Latest Sports" /> 
            </div>
            <br></br><br></br>

            <Title title="Sports #" />
            <div className="services-center-secondLine">
            <img src={require('../assets/images/New_Images/IPL_2024.png')} className="service"   alt="IPL_2024" />
            <img src={require('../assets/images/New_Images/ICC_T20_WORLD_CUP_2024.png')} className="service"   alt="ICC_T20_WORLD_CUP_2024" />
            <img src={require('../assets/images/New_Images/blank.png')} className="service"   alt="" />
            </div>
                <br></br>
                <div className ="centralized-div-for-book">
                    # Please check the schedule dates of release/event before booking.
                </div>
            <div className ="whiteBreakThin"> </div>
            <div className ="blueBreakThin"> </div>
            <div className ="whiteBreakThin"> </div>
            <div className ="greenBreakThin"> </div>
            </section>
            </div>
        )
    }
}
