import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';

export default function PrivacyPolicy() {

    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[Privacy Policy] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);
    },[]);
    
    return (
        <div className ="centralized-div">
        <h4><b>Privacy Policy</b></h4>

        <div className ="faqText ">
        Homeplex Entertainment Enterprises <b>(“Biggview”)</b> appreciates that you share your trust in us to deal with your personal information carefully and sensibly. This policy describes the privacy policy of Biggview. By visiting www.biggview.com (the <b>“Website”</b>), you are accepting and consenting to the practices described in this Privacy Policy.

<br></br><br></br>
<b>Controllers of Personal Information</b>
<br></br><br></br>
Any personal information provided to or gathered by Biggview under this Privacy Policy will be stored and controlled by Biggview.
<br></br><br></br>
<b>What Personal Information About Customers Does Biggview Gather?</b>
<br></br><br></br>
The information we learn from Website users helps us personalise and continually improve your experience of using the Website. We use your information to provide you details of the services we provide at Biggview including the provision of theater experiences at the users’ homes, etc. We also use this information to improve our platform, prevent or detect fraud or abuses of our Website and enable third parties to carry out technical, logistical or other functions on our behalf.
<br></br><br></br>
Here are the types of information we gather.
<br></br><br></br>
<b>Information You Give Us</b>
<br></br><br></br>
We receive and store any information you enter on our Website or give us in any other way. You can choose not to provide certain information, however, that may affect our capability to provide you with the aforesaid services/information about Biggview. We use the information that you provide for purposes such as providing the aforesaid services, improving our platform and communicating with you. The information shared by you will be strictly confidential and will only be used by Biggview for the purpose of providing services. 
<br></br><br></br>
<b>Automatic Information </b>
<br></br><br></br>
We receive and store certain types of information whenever you interact with us. For example, like many websites, we use "cookies" and we obtain certain types of information when your web browser accesses Biggview and other content served by or on behalf of Biggview on other web sites. 
<br></br><br></br>
<b>E-mail Communications</b>
<br></br><br></br>
To help us make e-mails more useful and interesting, we often receive a confirmation when you open e-mail from Biggview if your computer supports such capabilities. If you do not want to receive e-mail or other mail from us, please adjust your customer communication preferences accordingly.
<br></br><br></br>
<b>Information from Other Sources</b>
<br></br><br></br>
We might receive information about you from other sources and add it to your information.
<br></br><br></br>
By using or continuing to use the Website, you agree to our use of your information (including sensitive personal information, if any) in accordance with this Privacy Policy, as may be amended from time to time by Biggview in its discretion. You also agree and consent to us collecting, storing, processing, transferring and sharing information (including sensitive personal information, if any) related to you with third parties or service providers for the purposes as set out in this Privacy Policy.
<br></br><br></br>
We may be required to share the aforesaid information with government authorities and agencies for the purposes of verification of identity or for prevention, detection, investigation including cyber incidents, prosecution and punishment of offences. You agree and consent for Biggview to disclose your information, if so required under the applicable laws.
<br></br><br></br>
<b>What About Cookies?</b>
<br></br><br></br>
•	Cookies are alphanumeric identifiers that we transfer to your computer's hard drive through your web browser to enable our systems to recognise your browser and to provide certain features on the Website.
<br></br><br></br>
•	The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies altogether. Additionally, you can disable or delete similar data used by browser add-ons, such as Flash cookies, by changing the add-on's settings or visiting the website of its manufacturer. However, because cookies allow you to take advantage of some of Biggview's essential features, we recommend that you leave them turned on.
<br></br><br></br>
•	If you do leave cookies turned on, be sure to sign off when you finish using a shared computer.
<br></br><br></br>
<b>Does Biggview share the Information it receives?</b>
<br></br><br></br>
Information about our customers is an important part of our business and we are not in the business of selling it to others. Biggview shares customer information only as described under this Privacy Policy and with the subsidiaries/affiliates of Biggview (if required) and that are either subject to this Privacy Policy or follow practices at least as protective as those described in this Privacy Policy.
<br></br><br></br>
<b>•	Affiliated Businesses We Do Not Control: </b> We may work with our affiliated businesses or provide you services jointly with or on behalf of these businesses. We may share your information with such businesses on a need-to-know basis and require them to follow practices at least as protective as those described in this Privacy Policy.
<br></br><br></br>
<b>•	Third Party Service Providers: </b>We employ other companies and individuals to perform functions on our behalf. These service providers may have access to your personal information needed to perform their functions, but may not use it for other purposes. Further, they will process the personal information in accordance with this Privacy Policy and as permitted by the applicable law.
<br></br><br></br>
<b>•	Business Transfers: </b>As we continue to develop our business/services, we might sell, buy, restructure or reorganize our subsidiaries or other business units. In such transactions, your information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Policy (unless, of course, you consent otherwise). Also, in the unlikely event that Biggview or substantially all of its assets are acquired, customer information will of course be one of the transferred assets.
<br></br><br></br>
<b>•	Protection of Biggview and Others: </b> : We may release details submitted by you and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Conditions of Use and other agreements; or protect the rights, property or safety of Biggview, our users or others. This includes exchanging information with other companies, organisations, government or regulatory authorities for fraud protection and credit risk reduction. However, this does not include selling, renting, sharing or otherwise disclosing personally identifiable information from customers for commercial purposes in a way that is contrary to the commitments made in this Privacy Policy.
<br></br><br></br>
<b>•	With your consent: </b>Other than as set out above, you will receive notice when information about you might go to third parties and you will have an opportunity to choose not to share the information.
<br></br><br></br>
Whenever we transfer personal information to countries outside India in the course of sharing information as set out above, we will ensure that the information is transferred in accordance with this Privacy Policy and as permitted by the applicable laws including but not limited to Information Technology Act, 2000 and the rules framed thereunder.
<br></br><br></br>
<b>How Secure Is Information About Me?</b>
<br></br><br></br>
•	We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input in addition to maintaining security of your information as per the International Standard IS/ISO/IEC 27001 on "Information Technology Security Techniques Information Security Management System-Requirements".
<br></br><br></br>
•	We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personal information (including sensitive personal information, if collected). Our security procedures may require that we occasionally request proof of identity before we disclose personal information to you.
<br></br><br></br>
•	It is important for you to protect against unauthorised access to your password and to your computer. Be sure to sign off when you finish using a shared computer. 
<br></br><br></br>
<b>What About Third-Party Advertisers and Links to Other Web sites?</b>
<br></br><br></br>
Our Website includes third-party advertising and links to other websites. 
<br></br><br></br>
<b>What Information Can I Access?</b>
<br></br><br></br>
Biggview gives you access to a broad range of information about your information and your interactions with Biggview for the limited purpose of viewing and, in certain cases, updating that information. This list will change as our Website evolves.
<br></br><br></br>
<b>What Choices Do I Have?</b>
<br></br><br></br>
•	As discussed above, you can always choose not to provide information, even though it might be needed to take advantage of the services provided by us or other Website features.
<br></br><br></br>
•	If you do not want to receive e-mail or other mail from us, please adjust your customer communication preferences accordingly. If you do not want to receive Conditions of Use and other legal notices from us, such as this Privacy Policy, those notices will still govern your use of the Website, and it is your responsibility to review them for changes.
<br></br><br></br>
<b>Are Children Allowed to Use the Website?</b>
<br></br><br></br>
Use of the Website is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may use the Website only with the involvement of a parent or guardian.
<br></br><br></br>
<b>Notices and Revisions</b>
<br></br><br></br>
If you have any concern about privacy or grievances at Biggview, please contact us at contact@biggview.com with a thorough description and we will try to resolve the issue for you.
<br></br><br></br>
Our business changes constantly and our Privacy Policy and the Conditions of Use will change also. We may e-mail periodic reminders of our notices and conditions, unless you have instructed us not to, but you should check our Website frequently to see recent changes.
<br></br><br></br>
Unless stated otherwise, our current Privacy Policy applies to all information that we have about you. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of customer information collected in the past without the consent of affected customers.
<br></br><br></br>

















        <div className ="whiteBreakThin"> </div>
        <div className ="blueBreakThin"> </div>
        <div className ="whiteBreakThin"> </div>
        <div className ="greenBreakThin"> </div>
        </div>
        </div>
           /*
            <Cover coverClass="noimageHero">
                <Banner 
                title=""
                subtitle ="About us"
                >

                </Banner>
           </Cover> */
    )
}

