import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';

export default function FaqBV() {
    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[FAQ] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);
    },[]);
    
    return (
        <div className ="centralized-div">
            <h4><b>FAQs (Frequently Asked Questions)</b></h4>

            {/* <Cover coverClass="noimageHero">
                <Banner 
                title=""
                subtitle = "FAQs"
                >
                </Banner>
            </Cover> */}
           
            <div className ="faqText ">

<p className = "questions">1. Our room has more natural light. Will it impact the viewing experience? </p>
<br></br>
Although we use high lumen intensity projector, we recommend minimum light in the room for better viewing experience. Closing window/balcony doors and curtains helps reducing the light in the room.
<br></br><br></br><br></br>
<p className = "questions">2. We have to compromise on audio as it would disturb our neighbours ? </p>
<br></br>
Factors like common walls, wall thickness, number of windows etc allow sound going out. However keeping the door / window glasses fully closed helps minimize leaking of sound outside and you can use it at an optimize level without effecting your viewing experience and your neighbours.
<br></br><br></br><br></br>

<p className = "questions">3. We had a different viewing experience of this services at my home as compared to the one I had at my friend place ? </p>
<br></br>
As the surrounding environment (Hall Size, projection distance, Light etc) of every House is different, therefore the viewing experience will also vary and hence cannot be compared.
<br></br><br></br><br></br>
<p className = "questions">4. We are celebrating our kids birthday. Can we book the services ? </p>
<br></br>
The equipment setup will only be done in a closed environment like that of living room, living room + dining room, party hall less than 3000 sq ft. The set up will not be done in open areas like Ampi-theatre, terrace, Balcony, basement etc. So, for a Birthday Party you can book the services. Please note that the projector rays are harmful to the eyes, if viewed directly on its lens. In this regard, be advised to take extra precautions especially with children, and keep a safe distance from the projector to avoid any discomfort or harm.
<br></br><br></br><br></br>
<p className = "questions">5. How can the set up done in our 2/3 BHK house as it require space ? </p>
<br></br>
The set up require minimum space of 9 feet X 10 feet or 2.75 meters X 3.00 meters excluding the sitting space for the viewers. Refer some tips below to create space in your house – 
<br></br>
a)  Combining the dining & living room area helps creating space
<br></br>
b)  Temporary relocating furniture items helps creating space
<br></br><br></br><br></br>
<p className = "questions">6. How long the booking can be done late night ?</p>
<br></br>
Installation / Un installation will be done between 9 AM ~ 11 PM.
<br></br><br></br><br></br>
<p className = "questions">7. Is there any minimum commitment for booking hours ?</p>
<br></br>
Minimum booking hours is 2 hours. 
<br></br><br></br><br></br>




<div className ="whiteBreakThin"> </div>
<div className ="blueBreakThin"> </div>
<div className ="whiteBreakThin"> </div>
<div className ="greenBreakThin"> </div>
</div>

</div>
    )
}

