
import room3 from "../assets/images/gamingLogo.png";
import img1 from "../assets/images/gamingLogo.png";
import img2 from "../assets/images/gamingLogo.png";
import img3 from "../assets/images/gamingLogo.png";
import family2 from "../assets/images/gamingLogo.png";
import family3 from "../assets/images/gamingLogo.png";

import family4 from "../assets/images/gamingLogo.png";
import family5 from "../assets/images/gamingLogo.png";
import img9 from "../assets/images/gamingLogo.png";
import img11 from "../assets/images/gamingLogo.png";
import img12 from "../assets/images/gamingLogo.png";
import game1 from "../assets/images/gamingLogo.png";
import game2 from "../assets/images/gamingLogo.png";
import game3 from "../assets/images/gamingLogo.png";
import game4 from "../assets/images/gamingLogo.png";
import game5 from "../assets/images/gamingLogo.png";
import father from "../assets/images/gamingLogo.png";

export default [
  {
    sys: {
      id: "1"
    },
    fields: {
      name: "Movies with friends",
      slug: "movies-with-friends",
      type: "single",
      price: 400,
      size: 300,
      capacity: 1,
      description:
        "Enjoy Movies,Games,IPL matches,Web series and more...at the comfort of your home.",
      extras: [
        "Order food and berveges online and enjoy watching BIGG....",
        "Pause and rewind the content as per your wish...Watch smart and BIGG..."
        
      ],
      images: [
        {
          fields: {
            file: {
              url: img1
            }
          }
        },
        {
          fields: {
            file: {
              url: img11
            }
          }
        },
        {
          fields: {
            file: {
              url: img3
            }
          }
        },
        {
          fields: {
            file: {
              url: img12
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "Enjoy Games",
      slug: "enjoy-games",
      type: "single",
      price: 400,
      size: 300,
      capacity: 1,
      description:
        "Enjoy PS4 and PS5 and online games with Kids",
      extras: [
        "Treat yourself and your kids with best gaming experience"
      ],
      images: [
        {
          fields: {
            file: {
              url: img9
            }
          }
        },
        {
          fields: {
            file: {
              url: img2
            }
          }
        },
        {
          fields: {
            file: {
              url: game1
            }
          }
        },
        {
          fields: {
            file: {
              url: game2
            }
          }
        },
        {
          fields: {
            file: {
              url: game3
            }
          }
        },
        {
          fields: {
            file: {
              url: game4
            }
          }
        },
        {
          fields: {
            file: {
              url: game5
            }
          }
        }

      ]
    }
  },
  {
    sys: {
      id: "3"
    },
    fields: {
      name: "Family Time",
      slug: "family time",
      type: "single",
      price: 400,
      size: 300,
      capacity: 1,
      pets: true,
      breakfast: false,
      featured: false,
      description:
        "Family time like never before... Enjoy movies,games ,web series and LIVE matches and still maintain social distancing.",
      extras: [
        "Enjoy multiple content on Netflix,Amanzon prime and online vedios and TV channels on BIGG screens at very less price",
      ],
      images: [
        {
          fields: {
            file: {
              url: family2
            }
          }
        },
        {
          fields: {
            file: {
              url: family3
            }
          }
        },
        {
          fields: {
            file: {
              url: family4
            }
          }
        },
        {
          fields: {
            file: {
              url: family5
            }
          }
        }
      ]
    }
  },
  //////////////////////////////////////////////
  
  {
    sys: {
      id: "8"
    },
    fields: {
      name: "Movies with friends",
      slug: "movies-with-friends",
      type: "single",
      price: 400,
      size: 300,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Enjoy Movies,Games,IPL matches,Web series and more...at the comfort of your home.",
      extras: [
        "Order food online",
        "Order berveges online and enjoy watching BIGG....",
        "Pause and rewind the content as per your wish...",
        "Watch smart and BIGG..."
      ],
      images: [
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: img11
            }
          }
        },
        {
          fields: {
            file: {
              url: img3
            }
          }
        },
        {
          fields: {
            file: {
              url: img12
            }
          }
        }
      ]
    }
  },
 

 
  {
    sys: {
      id: "12"
    },
    fields: {
      name: "Enjoy Games",
      slug: "enjoy-games",
      type: "single",
      price: 400,
      size: 300,
      capacity: 6,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Enjoy PS4 and PS5 and online games with Kids",
      extras: [
        "Treat yourself and your kids with best gaming experience"
      ],
      images: [
        {
          fields: {
            file: {
              url: img9
            }
          }
        },
        {
          fields: {
            file: {
              url: img2
            }
          }
        },
        {
          fields: {
            file: {
              url: game1
            }
          }
        },
        {
          fields: {
            file: {
              url: game2
            }
          }
        },
        {
          fields: {
            file: {
              url: game3
            }
          }
        },
      ]
    }
  },
  {
    sys: {
      id: "13"
    },
    fields: {
      name: "Family Time",
      slug: "family-time",
      type: "single",
      price: 400,
      size: 300,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Family time like never before... Enjoy movies,games ,web series and LIVE matches and still maintain social distancing.",
      extras: [
        "Enjoy multiple content on Netflix,Amanzon prime and online vedios and TV channels on BIGG screens at very less price"
      ],
      images: [
        {
          fields: {
            file: {
              url: father
            }
          }
        },
        {
          fields: {
            file: {
              url: family3
            }
          }
        },
        {
          fields: {
            file: {
              url: family4
            }
          }
        },
        {
          fields: {
            file: {
              url: family5
            }
          }
        }
      ]
    }
  }
];
