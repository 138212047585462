import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';



export default function TermsCondition() {

    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[Terms & Conditions] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);
    },[]);
    
    return (
        <div className ="centralized-div">
        <h4><b>Key Requisites for Availing the Services</b></h4>
        
        <div className ="faqText ">
        1.	The video audio experience on a screen of 120 inches will be better if seen from a distance of 10 (ten) feet / 3 (three) meters from the screen. In this regard, please note that a minimum free space area measuring 9 feet X 10 feet or 2.75 meters X 3.00 meters, is required for the set-up, which excludes the sitting space for the viewers. 
        <br></br><br></br>
        2.	The set-up of the equipment is only for closed areas like living room, living + dining space, party hall (less than 3000 sq ft) etc. and <b>NOT FOR</b> open areas like balcony, terrace, basement, ampi theatre etc. Such set-up shall be subject to Biggview’s absolute comfort and in accordance with applicable laws. 
       <br></br><br></br>
       3.	Please note that the projector rays are harmful to the eyes, if viewed directly on its lens. In this regard, be advised to take extra precautions especially with children, and keep a safe distance from the projector to avoid any discomfort or harm.
      <br></br><br></br>
      4.	Biggview shall not be liable or responsible to provide services in the following cases subject to sole discretion of Biggview:
      <br></br><br></br>
      i)	The House / Floor / Block / Tower / Apartment is under quarantine due to the current pandemic situation or is unreachable due to reasons not attributable to Biggview; or
      <br></br>
      ii)	To the best knowledge and opinion of Biggview and, or, its representative the environment is not suitable for the equipment set up. Example – presence of a water / heat source near the place proposed for the set up; or
      <br></br>
      iii)	Any prohibition/ restriction under applicable laws.
      <br></br>
      *Please note that these requisites shall at all time to read with the Conditions of Use.
      <br></br><br></br>
      </div>
      <h4><b>Conditions of Use</b></h4>
      
      These terms and conditions <b>(“Conditions of Use”)</b> apply to your use of the website https://www.biggview.com/ <b>(“Website”)</b> operated and managed by Homeplex Entertainment Enterprises <b>(“Biggview”)</b>. Please read these Conditions of Use carefully before using the Website. By using the Website, you signify your agreement to be bound by these Conditions of Use:
      <div className ="faqText ">
      1.	Person booking the service should be above 18 years of age.
      <br></br><br></br>
      2.	Identity and age verification of the person booking the service shall be done through a valid original photo ID (Driving License / Aadhar Card / Passport / PAN Card).
      <br></br><br></br>
      3.	You will be liable for any damage caused to equipment to an extent of repair charges incurred to bring it to normal functioning or In case of damage beyond repair, you will have to bear the bill value of the product. It is clarified that the demand made by Biggview on the basis of whatever stated earlier, shall be the final and binding.
      <br></br><br></br>
      4.	Biggview will not be held responsible for any failure or interruption or loss incurred or delay to comply with its obligations under these conditions from any cause which is beyond the reasonable control of Biggview, which may include inter alia power failure, poor image quality due to low speed internet connection/ Non-HD set up box / Non-HD file, corrupt movie file etc., it is clarified that Biggview shall in no event bear any costs in this regard.
      <br></br><br></br>
      5.	You must take utmost precaution in keeping the volume high enough, so as not to disturb neighbours while availing this service. Biggview will not take any responsibility or will not be liable for any nuisance / disturbance caused to neighbour.
      <br></br><br></br>
      6.	Biggview shall only be responsible for setting up the equipment for necessary viewing and the Biggview associate will leave the premises after set up and necessary explanation. Please note that the associate is merely a service provider for Biggview and is not an employee or agent, accordingly Biggview shall not in any way be liable for any untoward actions done by the associate, if any.
      <br></br><br></br>
      7.	Please note that you are prohibited from viewing any content which is legally restricted/ prohibited by law on the equipment set-up by Biggview. Further, Biggview shall not in any manner be responsible for any violation in this regard whatsoever.
      <br></br><br></br>
      <b>3D Viewing</b>
      <br></br> <br></br>
      8.  If you or your family has a history of epilepsy or photosensitive seizures, consult a doctor before exposing yourself to flashing light sources, rapid image sequences or 3D viewing.
      <br></br><br></br>
      9.  The following people are not advised to use these 3D glasses : pregnant women, infirm persons, persons with heart issues as well as persons who become nauseous easily.
      <br></br><br></br>
      In addition, when you use/avail/request any current or future service on the Website <b>("Biggview Service(s)") </b>, you will also be subject to the terms, guidelines and conditions applicable to that Biggview Service <b>("Terms")</b> as updated from time to time.
      <br></br><br></br>
      These Conditions of Use constitute an electronic record within the meaning of the applicable laws. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is divided into the following three broad heads:
      <br></br><br></br>
      •	Conditions Relating to your use of the Website.
      <br></br>
      •	Notice and Procedure for Claiming Refund.
      </div>
      <h4><b>Conditions Relating to Your Use of the Website</b></h4>
      <div className ="faqText ">
      <b>1.	Your Information</b>
      <br></br>
      If you use the Website, you are responsible for maintaining the confidentiality of the information submitted by you and for restricting access to your computer to prevent unauthorised access to such information.  Please ensure that the details you provide us with are correct and complete and inform us immediately of any changes to the information that you provided when registering. Biggview reserves the right to refuse access to the Website, remove or edit content at any time without notice to you.
      <br></br><br></br>
      <b>2.	Privacy</b>
      <br></br>
      Please review our Privacy Notice, which also governs your visit to the Website and the information you share with us, to understand our practices. The personal information / data provided to us by you during the course of usage of the Website will be treated as strictly confidential and shall be dealt with in accordance with the Privacy Notice and applicable laws and regulations. If you object to your information being transferred or used, please do not use the Website.
      <br></br><br></br>
      <b>3.	E-Platform providing details of Biggview and Biggview Services</b>
      <br></br>
      You agree, understand and acknowledge that the Website is an online platform that provides you with details about Biggview and Biggview Services, enables you to avail Biggview Services. 
      <br></br><br></br>
      <b>4.	Access to the Website</b>
      <br></br>
      We will do our utmost to ensure that availability of the Website will be uninterrupted and that transmissions will be error-free. However, due to the nature of the internet, this cannot be guaranteed. Also, your access to the Website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.
      <br></br><br></br>
      <b>5.	Licence for Website Access</b>
      <br></br>
      Biggview grants you a limited licence to access and make personal use of the Website, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Biggview and/or its affiliates, as may be applicable. This licence does not contemplate any resale or commercial use of this Website or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this Website or its contents; any downloading or copying of account information; or any use of data mining, robots, or similar data gathering and extraction tools.
      <br></br><br></br>
      This Website or any portion thereof (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent of Biggview and/or its affiliates, as may be applicable.
      <br></br><br></br>
      You shall not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout or form) of Biggview and/or its affiliates. You shall not use any meta tags or any other "hidden text" utilising Biggview’s or its affiliates' names or trademarks without the express written consent of Biggview and/or its affiliates, as applicable. Any unauthorised use terminates the permission or license granted by Biggview and/or its affiliates, as applicable.
      <br></br><br></br>
      You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of the Website as long as the link does not portray Biggview and/or their affiliates, or their products or Biggview Services in a false, misleading, derogatory, or otherwise offensive matter. 
      <br></br><br></br>
      You may not use any Biggview logo or other proprietary graphic or trademark as part of the link without express written consent of Biggview and/or its affiliates, as may be applicable.
      <br></br><br></br>
      <b>6.	Your Conduct</b>
      <br></br>
      You must not use the Website in any way that causes, or is likely to cause, the Website or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not Biggview, are responsible for all electronic communications and content sent from your computer to us and you must use the Website for lawful purposes only. You must not use the Website for any of the following purposes:
      <br></br><br></br>
      o	for fraudulent purposes, or in connection with a criminal offense or other unlawful activity;
      <br></br>
      o	to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam”; or
      <br></br>
      o	to cause annoyance, inconvenience or needless anxiety.
      <br></br><br></br>
      <b>7.	Reviews, comments, communications and other content</b>
      <br></br>
      Users of the Website may post reviews, comments and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." 
      <br></br>
      In the event a user uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content, Biggview reserves the right (but not the obligation) to remove, refuse, delete or edit any content that in the sole judgement of Biggview violates these Conditions of Use and, or terminate the user’s permission to access or use the Website.
      <br></br><br></br>
      <b>8.	Copyright, authors' rights and database rights</b>
      <br></br>
      All content included on the Website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Biggview, its affiliates or its content suppliers and is protected by Indian and international copyright, authors' rights and database right laws. The compilation of all content on this Website is the exclusive property of Biggview and its affiliates and is protected by laws of India and international copyright and database right laws. All software used on this Website is the property of Biggview, its affiliates or its software suppliers and is protected by Indian and international copyright and author' rights laws.
      <br></br><br></br>
      You may not systematically extract/ or re-utilise parts of the contents of the Website without Biggview’s and/or its affiliate's (as may be applicable) express written consent. In particular, you may not utilise any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this Website, without Biggview’s and/or its affiliate's (as may be applicable) express written consent. You may also not create and/or publish your own database that features substantial (eg: prices and product listings) parts of this Website without Biggview’s and / or its affiliate's (as may be applicable) express written consent.
      <br></br><br></br>
      <b>9.	Intellectual Property Claims</b>
      <br></br>
      Biggview and its affiliates respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please contact us at <u>contact@biggview.com.</u> 
      <br></br><br></br>
      <b>10.	Trademarks</b>
      <br></br>
      <div>
     <img src={require('../assets/images/small_icon.png')} width="77" height="50" alt="Bigg View" />
      and other marks indicated on our website are trademarks/registered trademarks of Biggview or its subsidiaries, in India. Biggview's graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress of Biggview. Biggview's trademarks and trade dress may not be used in connection with any product or service that is not Biggview's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Biggview. All other trademarks not owned by Biggview that appear on this Website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Biggview.
      <br></br><br></br>
      <b>11.	Disclaimer</b>
      <br></br>
      You acknowledge and undertake that you are accessing Biggview Services at your own risk and are using your best and prudent judgment before entering into transactions through the Website (directly or indirectly including via SMS), if any. We shall neither be liable nor responsible for any actions or inactions of other third parties nor any breach of conditions, representations or warranties by any third parties (whether or not with respect to the use of the Website) and hereby expressly disclaim and any all responsibility and liability in that regard. 
      <br></br><br></br>
      We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or transacted or the content (including product information and/or specifications) on the Website. While we have taken precautions to avoid inaccuracies in content, this Website, all content, information, software, products, services and related graphics are provided as is, without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of any products on the Website. At no time shall any right, title or interest in the services sold displayed or advertised on the Website vest with Biggview nor shall Biggview have any obligations or liabilities in respect of any transactions on the Website.
      <br></br><br></br>
      <b>12.	Indemnity and Release</b>
      <br></br>
      You shall indemnify and hold harmless Biggview, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Conditions of Use or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.
      <br></br><br></br>
      You hereby expressly release Biggview and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors and specifically waive any claims or demands that you may have in this behalf under any statute, contract or otherwise.
      <br></br><br></br>
      <b>13.	Children </b>
      <br></br>
      Use of the Website is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may use the Website only with the involvement of a parent or guardian.
      <br></br><br></br>
      <b>14.	Other Businesses</b>
      <br></br>
      We may provide links to the websites of other affiliated companies and certain other businesses. We are not responsible for examining or evaluating, and we do not warrant or endorse the offerings of any of these businesses or individuals, or the content of their websites. Biggview does not assume any responsibility or liability for the actions, products, and content of any of these and any other third-parties. You should carefully review their privacy statements and other conditions of use.
      <br></br><br></br>
      <b>15.	Communications</b>
      <br></br>
      When you visit the Website or send e-mails to us, you are communicating with us electronically. You will be required to provide a valid phone number while making such communications. We may communicate with you by e-mail, SMS, phone call or by posting notices on the Website or by any other mode of communication. For contractual purposes, you consent to receive such communications from us.
      <br></br><br></br>
      <b>16.	Losses</b>
      <br></br>
      We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using the Website.
      <br></br><br></br>
      <b>17.	Alteration of Service or Amendments to the Conditions</b>
      <br></br>
      We reserve the right to make changes to our Website, policies, and these Conditions of Use at any time. You will be subject to the policies and Conditions of Use in force at the time that you use the Website, unless any change to those policies or these conditions is required to be made by law or government authority. If any of these Conditions of Use is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.
      <br></br><br></br>
      <b>18.	Events beyond our reasonable control</b>
      <br></br>
      We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. 
      <br></br><br></br>
      <b>19.	Waiver</b>
      <br></br>
      If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.
      <br></br><br></br>
      <b>20.	Governing Law and Jurisdiction</b>
      <br></br>
      These Conditions of Use are governed by and construed in accordance with the laws of India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Bengaluru.
      <br></br><br></br>
      <b>21.	Our Details</b>
      <br></br>
      This website is operated by Biggview, presently. For queries/issues related to the Website or its use, please contact us by visiting: <u>contact@biggview.com</u>
      <br></br><br></br>
      <b>22.	Procedure for Claiming a Refund</b>
      <br></br>
      In the event you have made an advance payment for availing Biggview Services and the same have not been availed due to reasons which are attributable to Biggview, in its sole discretion and understanding, you may file a claim for refund by emailing to us at contact@biggview.com, along with relevant details including inter alia order confirmation, order ID, payment details, reasons for seeking refund, proof of statement seeking refund and receipts of payment in such regard. Such refund shall be issued on the sole discretion of Biggview. 
      <br></br><br></br>
      It is clarified that Biggview shall not issue any refund for cases where the Biggview Services have not been availed by you on their own account, pursuant to making such payments. 
      <br></br><br></br>
      Only valid payment methods acceptable to Biggview may be used, for placing all requests and orders and all refunds will be credited back to the same source.
      <br></br><br></br>
      You acknowledge and agree that with respect to any payments by credit card or electronic funds transfer, when submitting the order for processing, you hereby authorize Biggview to charge the order (including taxes, shipping, handling and any amounts agreed upon before order submission) to your credit card or account. In this regard, if your credit card or account of cannot be verified, or is invalid, or is not otherwise acceptable, the order may be suspended or cancelled automatically.
      <br></br><br></br>
      It is clarified hereby and agreed by you that under no event shall any claim for refund, damages, losses etc. exceed the amounts actually paid by you to Biggview for availing the Biggview Services, and the same shall be subject to deductions of any expenses borne by Biggview in such regard.
      <br></br><br></br>
      <b>23.	Cancellation Policy</b>
      <br></br>
      In the event the reservation for Biggview Services is,
      <br></br><br></br>
      o	Canceled 48 (forty-eight) hours prior to the commencement of the Biggview Services, the amount paid by you will be refunded, in its entirety;
      <br></br><br></br>
      o	Canceled between 24 (twenty-four) to 48 (forty-eight) hours from the commencement of the Biggview Services, the amount paid by you will be refunded subject to charging of a 50% (fifty percent) cancellation fee due and payable immediately upon such cancellation;
      <br></br><br></br>
      o	100% (hundred per cent) cancellation charges shall be charged on all cancellations less than 24 hours from the commencement of the Biggview Services.
      <br></br><br></br>
      In this regard, please note that any cancellation charges that may be payable shall be refunded in 5 (five) to 7 (seven) working days from the date of receipt of request/ documents in this regard. 
      <br></br><br></br>
      <b>Important Warning:</b> Giving false, misleading or inaccurate information in the communication to Biggview may result in civil and/or criminal liability. You should contact a legal advisor should you have any questions. Biggview reserves the right to change this payment, refunds policy terms and conditions at any time. Any such changes will take effect when posted on the website.
      <br></br><br></br>


      </div>
        <div className ="whiteBreakThin"> </div>
        <div className ="blueBreakThin"> </div>
        <div className ="whiteBreakThin"> </div>
        <div className ="greenBreakThin"> </div>
        </div>
        </div>
           /*
            <Cover coverClass="noimageHero">
                <Banner 
                title=""
                subtitle ="About us"
                >

                </Banner>
           </Cover> */
    )
}

