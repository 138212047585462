import React, {useState,useEffect,useRef} from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Services from '../components/Services';
import Title from '../components/Title';
import ReactPlayer from "react-player";
import Popup from '../common/Popup';




const Home: React.FC = () => {

  
  
    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[HOME] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);
     // openModal();
    },[]);

  const [isOpen, setIsOpen] = useState(false);
  let subtitle = '';
  

  // function openModal() {
  //   setIsOpen(true);
  // }

  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //  // subtitle.style.color = '#f00';
  // }
  
  // function closeModal() {
  //   setIsOpen(false);
  // }
  // const togglePopup = () => {
  //   setIsOpen(!isOpen);
  // }

    return (
      <>
        <React.Fragment>
           {/*<Cover>
                <Banner 
                title=""
                subtitle=""
                >
                </Banner>
           </Cover> */}
            
            {/* <FeaturedRooms /> */}

<div className = "defaultHero">

<div>
  <table  className = "ssImageWidth w3-animate-right">
  <tr>
      <td>
          <div className="mySlides fade defaultHero">
          <div className="numbertext" id = "second">2 / 3</div>
          <div className = "topImpTextRental">Audiovisual Equipment Hire Service at your Home</div> 
          <br></br>
      
          {/* {isOpen && <Popup
           content={<div> </div>}
           handleClose={togglePopup}
          />
          } */}
           <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/Birthday Celebrations idea.png')}  className = "ssImageWidth w3-animate-right" alt="Make Birthday Celebrations more Lively and Engaging with Biggview. Kids can now watch a 3D movie on 120 inch screen with their friends. Create a small dance floor in your home for lots of fun and dance. Selecting a Birthday Theme is easy now which can relate to the Movie premiere. Display the memories of your loved ones on 120 inch screen. We provide a 120 inch screen, a full HD projector and a Dolby Atmos soundbar. In addition we also provide snacks items like Popcorn and Samosa " />
      <div>&nbsp;&nbsp;</div>
      
      </div>
      </td>
    </tr> 
    <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/WhyBiggview_2.png')}  className = "ssImageWidth w3-animate-right" alt="With Biggview you can now select a Birthday Theme, linked to the Movie planned to be showcased. Display photos of Birthday Boy or girl of how the child have grown year on year since birth. Create a dance floor in your living room for fun and dance.  " />
      <div>&nbsp;&nbsp;</div>
      </div>
      </td>
    </tr>

    <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/Birthday_Theme_Boys.png')}  className = "ssImageWidth w3-animate-right" alt="With Biggview you can now select a Birthday Theme, linked to the Movie planned to be showcased. Display photos of Birthday Boy or girl of how the child have grown year on year since birth. Create a dance floor in your living room for fun and dance.  " />
      <div>&nbsp;&nbsp;</div>
      </div>
      </td>
    </tr>

    <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/Birthday_Theme_Girls.png')}  className = "ssImageWidth w3-animate-right" alt="With Biggview you can now select a Birthday Theme, linked to the Movie planned to be showcased. Display photos of Birthday Boy or girl of how the child have grown year on year since birth. Create a dance floor in your living room for fun and dance.  " />
      <div>&nbsp;&nbsp;</div>
      </div>
      </td>
    </tr>

    <tr>
            <td>
            <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/Movie_Watch_3D.png')}  className = "ssImageWidth w3-animate-right" alt="First time in India, Now watch a 3D movie on 120 inch screen in the comfort and safety of your home. Check out our 3D movie collection which has animation and action movies to suit all types of audience " />
      <div>&nbsp;&nbsp;</div>
      </div>
            </td>
          </tr>
          
           <tr>
            <td>
            <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/Cinema_at_home.png')}  className = "ssImageWidth w3-animate-right" alt="Relatives, Kids, Friends at Home and you have no idea how to Engage them. With Biggview Entertain relatives, kids or friends which is convevient and cost effective. Entertain with Binge watching, Movie watching, 3D movie watching, Web series watching, On demand videos, Live Sports watching on a 120 inch screen, Full HD picture quality and an immersive Dolby Atmos surround sound. " />
      <div>&nbsp;&nbsp;</div>
      </div>
            </td>
          </tr>

          <tr>
            <td>
            <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/Family get together celebration ideas.png')}  className = "ssImageWidth w3-animate-right" alt="Now planning a get together is more easy and fun. Book Biggview for a perfect get together at home. Opt for a movie premiere, 3D movie watching, Live sports watching or binge watching. No more limitation to food choice, you can order anything online or prepare at home." />
      <div>&nbsp;&nbsp;</div>
      </div>
            </td>
          </tr>

          <tr>
            <td>
            <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">2 / 3</div>
      <img src={require('../assets/images/WhyBiggview_1.png')}  className = "ssImageWidth w3-animate-right" alt="Buying a Play Station console for your kid is never a good idea. It would addict your kid to games and make hin stay indoors. Instead good option is buy him few hours of Ultimate gaming experience with Biggview on a 120 inch screen and an immersive surround sound experience. " />
      <div>&nbsp;&nbsp;</div>
      </div>
            </td>
          </tr>
   
    

    <img src={require('../assets/images/Friends_get_together.png')}  className = "ssImageWidth w3-animate-right" alt=" No need to step out from your house for watching movies on big screen. With Biggview watch your favorite movie, new movie premiere in the Safety and Comfort of your living room. Say no to Traffic hustle or parking hustle. Say no to limited food options. Complete family can now watch movies similar to Cinema Hall experience on a 120 inch screen, Full HD picture quality and Dolby Atmos surround sound. " />
        
       </div>
      </td>
    </tr>
    <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "first">1 / 3</div>
      <img src={require('../assets/images/Cricket Watching on big screen.png')}  className = "ssImageWidth w3-animate-right" alt="With Biggview you get 3D, Full HD, Dolby Atmos similar to what you get in a Multiplex. In addition you are free to choose your movie and show timings. No need to get ready, drive down through traffic, parking hustle and parking charges. Watch in a informal / Bindaas manner. Re watch important movie scenes. No need to choose seats as all seats are premium. There is no seat more comfortable than the couch of your living room." />
      <div>&nbsp;&nbsp;</div>
     </div>
      </td>
    </tr>
    <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "second">3 / 3</div>
      <img src={require('../assets/images/Group party celebration ideas.png')}  className = "ssImageWidth w3-animate-right" alt="Reward or gift your child with an amazing play station gaming experience on a 120 inch screen, Full HD picture quality and an immersive surround sound experience. Choose from our Hourly / Daily rental packages.  " />
      <div>&nbsp;&nbsp;</div>
      
      </div>
      </td>
    </tr> 
    <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "first">1 / 3</div>
      <img src={require('../assets/images/PlayStationGaming.png')}  className = "ssImageWidth w3-animate-right" alt="Watching Live cricket or sports with friends is much more fun and lively. Group watching is back now." />
      <div>&nbsp;&nbsp;</div>
     
      
      </div>
      </td>
   </tr>
    <tr>
      <td>
      <div className="mySlides fade defaultHero">
      <div className="numbertext" id = "first">1 / 3</div>
      <img src={require('../assets/images/Play Station Gaming on Rent.png')}  className = "ssImageWidth w3-animate-right" alt="Which way of watching do you like the most ? Is it solo or in group ?" />
    
      
      </div>
      </td>
   </tr> 
    </table>
    {/* <div className ="centralized-div-for-services-div">
      <Services/>
      </div> */}
       <br></br> <br></br>
       <Title title="Snacks Offering:" />
      <div className="services">
      <img src={require('../assets/images/Airfried_Popcorn.png')}  alt="Airfried healthy mushroom Popcorn " />
      <img src={require('../assets/images/Airfried_Popcorn_Price.png')}  alt="Airfried Popcorn Price " />
      <img src={require('../assets/images/Samosa.png')}  alt="Delicious Samosa" />
      <img src={require('../assets/images/Samosa_Price.png')}  alt="Samosa Price " />
      </div>
      <br></br> <br></br>

       <Title title="Watch it Bigg, Connect for" />
                 <div className="services-center-secondLine">
                <img src={require('../assets/images/movieslogo.png')} className="service" alt="Connect Movies through your OTT subscription." /> 
                <img src={require('../assets/images/tvSetUpBoxlogo.png')} className="service" alt="TV set up box" /> 
                <img src={require('../assets/images/onlineStreamLogo.png')} className="service" alt="Connect laptop, tablet, smart phone, firestick" /> 
                <img src={require('../assets/images/EventsSportsLogo.png')} className="service" alt="Events and Sports" /> 
                <img src={require('../assets/images/gamingLogo.png')} className="service" alt="Play Station console, two controllers and many games to choose from" /> 
                </div>
                <br></br><br></br>
                <Title title="Customer Testimonial" />
                <div className="services-center-secondLine">

                <img src={require('../assets/images/Client_Testimonials/Google_Rating.png')} className="service" alt="Google Rating 5.0" />
                <div className ="youtube-video-container">
                <ReactPlayer
                 url="https://youtu.be/vdkx08h-HlE" 
                 width='180px'
                 height='310px'
                />
                </div>
                <img src={require('../assets/images/Client_Testimonials/Sowmya_text.png')} className="service" alt="Customer testimonial 2, Hari - Nice experience with my son. After a long time experienced my childhood memories of playing video games." /> 
                <div className ="youtube-video-container">
                <ReactPlayer
                 url="https://youtu.be/fgXpzvWEArA" 
                 width='180px'
                 height='310px'
                />
                </div>
                <img src={require('../assets/images/Client_Testimonials/Ashwini_text.png')} className="service"   alt="Customer testimonial 4, Mohan - Our potluck party got better with a movie premiere. A long needed break. It was fun." /> 
               

                    
                    {/* <img src={require('../assets/images/Client_Testimonials/Group Cricket Watching.png')} className="service" alt="Client testimonial" />  */}
                    <div className ="youtube-video-container">
                <ReactPlayer
                 url="https://youtu.be/-X44Nkpspbo" 
                 width='180px'
                 height='310px'
                />
                </div>
                    <img src={require('../assets/images/Client_Testimonials/Group_cricket_wathching_Testimonial.png')} className="service" alt="TV set up box" /> 
                    
                    <div className ="youtube-video-container">
                <ReactPlayer
                 url="https://youtu.be/hu_R5OO1tBk" 
                 width='180px'
                 height='310px'
                />
                </div>
                    <img src={require('../assets/images/Client_Testimonials/PS4_Gaming_Testimonial.png')} className="service"   alt="Latest Sports" /> 
                </div>

                <br></br> <br></br>

      <Title title="We Strictly follow Covid protocols:" />
      <section className="services">
      <img src={require('../assets/images/Covid_Protocol_Fully_Vaccinated.png')}  alt="Follow covid protocol fully vaccinated " />
      <img src={require('../assets/images/Covid_Protocol_Fully_Sanitize.png')}  alt="Follow covid protocol fully Sanitize equipment " />
      <img src={require('../assets/images/Covid_Protocol_Fully_Mask.png')}  alt="Follow covid protocol Wearing mask" />
      <img src={require('../assets/images/Covid_Protocol_Fully_Temp.png')}  alt="Follow covid protocol Temperature check of associates " />
      </section> 
      <section className="services">
      <img src={require('../assets/images/rental_includes.png')}  alt=" Rentals includes a 120 inch screen, a full HD projector and a Dolby Atmos surround sound soundbar" />
      <img src={require('../assets/images/introductory_offer_text.png')}  alt=" Rentals available on hourly or daily basis" />
      
      </section>
      <div className = "footerPriceText">
      Spending Time Together is <b>Priceless</b>, adding fun part to it costs <b>120 Rs / Hr*</b> &nbsp;
    
      <button className ="blueButtonFooter"> <Link to="/book">&nbsp;&nbsp;BOOK NOW&nbsp;&nbsp;</Link></button>
    
      </div>
      
      <div>* Condition Apply.</div>
            
            <div className ="greenBreakThin"> </div>
</div>
</div>

          {/* <Services/>  */}
        </React.Fragment>
        </>
    )
}

export default Home
