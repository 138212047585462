import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import ReactPlayer from "react-player";





export default function AboutBV() {

    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[About us] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);
    },[]);
    
    return (
        <div className ="centralized-div">
        <h4><b>About Us</b></h4>
        <div className="services-center-secondLine">
              <div className ="youtube-video-container">
                <ReactPlayer
                 url="https://youtu.be/jcZ4JvXgPwg" 
                 width='180px'
                 height='310px'
                />
                </div>
        </div>

        <div className ="faqText ">
        The journey of humankind shows the process of evolution and the simultaneous invention of technologically advanced products in all the dimension of life. One of the aspect which is an integral part of humans, is the world of entertainment as it continues to rejuvenate body & mind. Entertainment has existed for centuries through story telling, music, dance etc. Invention of television and  cinema added a new perspective to it . Over the past few decades we have seen a remarkable improvement  in video graphics and audio systems which are engaging, immersive and highly refined masterpiece.
<br></br><br></br>
After food, nothing binds an Indian family together like a Television set. During the 80’s, the most awaited moment throughout the weekdays was to watch epic serials, like Mahabharata and Ramayana on Sundays with all family members and neighbourhood. Kids loved to watch Mogli and Ducktales along with their friends. Early 90’s got flooded with entertainment channels, and introduction of flat , color TV sets took the viewing experience to next level. Remember any college campus where watching a cricket match together in a small common room felt not less than a stadium.
<br></br><br></br>
With improvement in technology television sets became bigger & slimmer, sound system covered all dimension & space, image quality became finer & sharper. Thanks to availability of high speed internet, which has given an access to viewing content anywhere, anytime. Though all these has improved our viewing experience, one important thing that is fading out is “togetherness”. Our busy life, staying away from family for jobs, independent lifestyle are some of the reason for this.
<br></br><br></br>
Through our cinematic rental services, we aim to bring back the factor “togetherness” back into your life. We use latest equipment, supported by unique design (registered) tools to provide you better viewing experience, that can be availed at your convenient time, in the comfort of your living room. We want you to explore the togetherness and the informal part of viewing experience. It can be chit chats, claps, funny comments when watching with your friends/colleagues/neighbour or hearing out your kids expression / query. And also be free choosing your breaks and snacks during this experience time. 
<br></br><br></br>
We look forward to hear out your informal viewing experience story. Kindly do mail us your story/feedback at feedback@biggview.com
<br></br>
<br></br>
Content Writer : Monalisa Pattanaik
<br></br>
<br></br>
        <div className ="whiteBreakThin"> </div>
        <div className ="blueBreakThin"> </div>
        <div className ="whiteBreakThin"> </div>
        <div className ="greenBreakThin"> </div>
        </div>
        </div>
           /*
            <Cover coverClass="noimageHero">
                <Banner 
                title=""
                subtitle ="About us"
                >

                </Banner>
           </Cover> */
    )
}

