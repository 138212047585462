import React, { Component } from 'react';

import { FaBars, FaFacebook } from 'react-icons/fa';
import { FaPhoneSquare } from 'react-icons/fa';
import { FaEnvelope} from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface IStateNavbar {
    isOpen: boolean;
}

export default class Navbar extends Component<{}, IStateNavbar> {

    public readonly state: Readonly<IStateNavbar> = {
        isOpen: false,
    }


    public render() {
        const { isOpen } = this.state;
        return (
            <nav className="navbar">
                <div className="nav-center">
                    <div className="nav-header">
                       <img src={require('../assets/images/small_icon.png')} width="158" height="103" alt="Bigg View" />
                        <button type="button" className="nav-btn" onClick={this.handleToggle}>
                            <FaBars className="nav-icon" />
                        </button>
                    </div>
                    <div className ="div-for-nav"> 
                       <div  className ="cursiveTop"> <div className ="cursiveDiv"> Explore Togetherness </div>
                       {/* <button><div> +91 83108 00634  <FaPhoneSquare /> </div> </button> */}
                       <a href="tel:+91 83108 00634">+91 83108 00634 <FaPhoneSquare /></a>
                    </div>
                    <ul className={isOpen ? "nav-links show-nav" : "nav-links"}>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/">&nbsp;Home&nbsp;</Link></li>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/aboutBV">&nbsp;About Us&nbsp;</Link></li>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/moviesEventsSports">Watch Out For</Link></li>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/whyBiggView">Why Bigg View</Link></li>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/rentalCharges">Rental Charges</Link></li>

                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/termsAndCondition">Terms & Condition</Link></li>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/book">&nbsp;BOOK &nbsp;&nbsp;NOW&nbsp;</Link></li>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/privacyPolicy">Privacy Policy</Link></li>
                        <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/contactUS">&nbsp;Contact Us&nbsp;</Link></li>
                       {/* <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/joinBV">&nbsp;Join Us&nbsp;</Link></li> */}
                       <li onClick={this.handleToggle} className ="borderedLinks"><Link to="/faqBV">&nbsp;&nbsp;&nbsp;FAQs&nbsp;&nbsp;&nbsp;</Link></li>
                    </ul>
                   
                    </div>
                    
                </div>
                <br></br>
                <div className ="whiteBreakThin"> </div>
                    <div className ="blueBreakThin"> </div>
                    <div className ="whiteBreakThin"> </div>
            </nav>
        )
    }

    private handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
}
