import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import Services from '../components/Services';
export default function MoviesEventsSports() {

    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[Watch out for] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);

    },[]);
    
    return (
        <div className ="centralized-div">

        <Services/>
        </div>
    
          /*  <Cover coverClass="noimageHero">
                <Banner 
                title=""
                subtitle ="Events"
               >
                </Banner>
            </Cover> */
    )
}

