import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { FaBeer, FaCalendarDay, FaCalendarTimes, FaClock, FaMailBulk, FaMailchimp, FaPhoneSquare, FaTimesCircle,FaFacebook } from 'react-icons/fa';

export default function ContactBV() {
    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[Contact Us] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);
    },[]);
    
    return (
      <div className="centralized-div">
        <h4><b>Contact us</b></h4>
        <div className="faqTextCentre">
          <div className="contactUsDivBordered">
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;
            </div>

            <div className="text-matching-icon">
              <FaClock className="nav-icon" />
              &nbsp;&nbsp;&nbsp;Mon-Sun
            </div>
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;Office Hours :  09:00 AM – 18:00 PM
            </div>
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;Installation/Un-installation Hours : 09:00 AM – 23:00 PM
            </div>
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;Booking Hours : 24 X 7
            </div>
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;
            </div>


            <div className="text-matching-icon">
              <FaPhoneSquare className="nav-icon" />
              &nbsp;&nbsp;&nbsp;<a href="tel:+91 83108 00634">+91 83108 00634</a>
            </div>
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;
            </div>
            <div className="text-matching-icon">
              <FaMailBulk className="nav-icon" />
              &nbsp;&nbsp;&nbsp;For Queries - contact@biggview.com
            </div>
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;
            </div>
            <div className="text-matching-icon">
              <FaMailBulk className="nav-icon" />
              &nbsp;&nbsp;&nbsp;For feedback - feedback@biggview.com
            </div>
            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;
            </div>
            <div className="text-matching-icon"> <FaFacebook className="nav-icon"/>  <a href="https://www.facebook.com/Biggview/?ref=pages_you_manage"> Facebook page </a>  </div>

            <div className="text-matching-icon">
              &nbsp;&nbsp;&nbsp;
            </div>


          </div>

          <br></br>
        </div>
        <div className="whiteBreakThin"> </div>
        <div className="blueBreakThin"> </div>
        <div className="whiteBreakThin"> </div>
        <div className="greenBreakThin"> </div>
      </div>
            /* <Cover coverClass="noimageHero">
          <Banner 
          title=""
          subtitle="Contact us "
          >
          </Banner>
      </Cover> */
    )
}

