import React, { useState,useEffect,useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { FaBeer, FaCalendarDay, FaCalendarTimes, FaClock, FaMailBulk, FaMailchimp, FaPhoneSquare, FaTimesCircle } from 'react-icons/fa';

export default function WhyBiggview() {
    useEffect(() => {
        "use strict";
        let device ;
        if(window.screen.width < 640)
        {
          device = 'mobile' ;
        }
        else{
          device = 'desktop';
        }
        let emailSendTo = "";
        let emailSentFrom = "";
        let emailSubject = "[Why Bigg View] on " + device;
        let bodyOfEmail = "";

        let payload = {
          Subject: emailSubject,
          Body: bodyOfEmail,
          SendTo: emailSendTo,
          SentFrom: emailSentFrom
      };
      require('../common/commonFunc').sendEmail(payload);
    },[]);
    
    return (
      <div className="centralized-div">
        <h4><b>Why Bigg view ?</b></h4>
        <div className="size-inputs rentalCharges">
        <div className="serviceBookingDiv">
        <div className="sideTextBoxes positionOfRates">
            <img src={require('../../src/assets/images/PlayStationGaming.png')} className="ssImageWidthForRate" alt="Bigg View Rates" />      
          </div>
          <div className="sideTextBoxes positionOfRates">
          <img src={require('../../src/assets/images/WhyBiggview_2.png')} className="ssImageWidthForRate" alt="Bigg View Rates" />            
          </div>
          <div className="sideTextBoxes positionOfRates">
            <img src={require('../../src/assets/images/WhyBiggview_1.png')} className="ssImageWidthForRate" alt="Bigg View Rates" />      
          </div>
        
          
        </div>
        </div>
        <div className="whiteBreakThin"> </div>
        <div className="blueBreakThin"> </div>
        <div className="whiteBreakThin"> </div>
        <div className="greenBreakThin"> </div>
      </div>
            /* <Cover coverClass="noimageHero">
          <Banner 
          title=""
          subtitle="Contact us "
          >
          </Banner>
      </Cover> */
    )
}

