import React, { useState,useEffect,useRef } from 'react';
import '../assets/styles/sass/index.scss';
import { Route, Switch } from 'react-router-dom';

import {  FaWhatsapp } from 'react-icons/fa';

import Navbar from '../components/Navbar';
import Home from '../pages/Home';
import Rooms from '../pages/Rooms';
import SingleRoom from '../pages/SingleRoom';
import ErrorPage from '../pages/ErrorPage';
import JoinBV from '../pages/JoinBV';
import AboutBV from '../pages/AboutBV';
import ContactBV from '../pages/ContactBV';
import FaqBV from '../pages/FaqBV';
import MoviesEventsSports from '../pages/MoviesEventsSports';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsCondition from '../pages/TermsCondition';
import RentalCharges from '../pages/RentalCharges';
import WhyBiggview from '../pages/WhyBiggView';

const App: React.FC = () => {
  useEffect(() => {
    "use strict";
    const script = document.createElement('script');
    script.src = "engine1/wowslider.js";
    script.async = true;
    document.body.appendChild(script);
   
    const script1 = document.createElement('script');
    script1.src = "engine1/script.js";
    script1.async = true;
    document.body.appendChild(script1);

    

  },[]);
  return (
    <React.Fragment>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/joinBV" component={JoinBV} />
        <Route exact path="/aboutBV" component={AboutBV} />
        <Route exact path="/contactUS" component={ContactBV} />
        <Route exact path ="/rentalCharges" component = {RentalCharges} />
        <Route exact path = "/whyBiggview" component =  {WhyBiggview}/>
        <Route exact path="/faqBV" component={FaqBV} />
        <Route exact path="/moviesEventsSports" component={MoviesEventsSports} />
        <Route exact path="/book/" component={Rooms} />
        <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/termsAndCondition" component={TermsCondition} />
        <Route exact path="/book/:slug" component={SingleRoom} />
        <Route component={ErrorPage} />
      </Switch>
      
      <a
        href="https://wa.me/+918310800634"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      > 
      <FaWhatsapp />
      </a>
    </React.Fragment>
  );
}

export default App;
