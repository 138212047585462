
import axios from 'axios';

export const sendEmail = function(payload) {

                     
                       //axios.post('http://localhost:8098/sendEmail',payload)
                        axios.post('https://www.biggview.com:8098/sendEmail',payload)
                        .then(function(response)
                        {
                         console.log("email sent to track clicks response is:" + response.data);
                        })
                        .catch(function(error)
                        {
                           console.log("error is:" + error);
                        });
                        
   }
   